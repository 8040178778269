import { graphql } from 'gatsby'
import React from 'react'

import img1 from '../../assets/blog/5/1.jpg'
import img2 from '../../assets/blog/5/2.jpg'
import img3 from '../../assets/blog/5/3.jpg'
import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Někdy není dobré hasit jen to, co hoří" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Někdy není dobré hasit jen to, co hoří" />
        <ContentSubPage>
          <p>
            Začátek roku je doba, kdy víc myslíme na své zdraví. Nový rok
            znamená restart a všichni přece chceme žít aspoň trochu lépe. Vedle
            suchého února nebo předsevzetí stran pravidelného sportu a
            makrobiotické stravy si mnozí také vzpomenou na preventivní
            prohlídky. Často to ale tím „vzpomenou” skončí. Ukazuje se, že
            preventivních programů využívá méně než polovina těch, kteří na ně
            mají nárok. Jde o nevědomost nebo odmítání lékařské péče? Občas ano,
            ale… jak je to u vás? Z naší miniankety mezi kamarády vyplynula spíš
            jiná příčina: zkrátka toho máme i tak nad hlavu.
          </p>
          <p>
            Preventivních programů je díkybohu hodně, o to těžší ale je všechny
            kontroly a jejich frekvence sledovat a dodržovat. I těm
            nejpečlivějším z nás se v každodenním shonu stane, že zapomeneme na
            to, kdy přesně na prohlídku jít. A tak často dochází k prodlevám v
            řádů měsíců až let, které ovšem, jak tušíme, mohou mít nepříjemné
            následky. A co teprve, když jste tím (nebo spíše tou), kdo návštěvy
            lékařů hlídá celé rodině?
          </p>
          <p>
            Nejmenovaná účastnice ankety dokonce přiznala, že když kdysi
            ztratila diář (noční můra pracujících matek), strávila dlouhé hodiny
            na telefonu jen aby zjistila, na kdy byl který člen rodiny kam
            objednán. A stejně nevychytala všechno.
          </p>
          <h3>Co s tím?</h3>
          <p>
            Zjednodušme si to. Za prvé, nesnažme se sami vyhodnotit, která
            kontrola ano a která se nám zdá zbytečná. Když už nám je pojišťovna
            nabízí, buďme si jistí, že to význam má. Jiné hlavy tam spočítaly
            statistiku výsledků a ekonomickou návratnost plošných testů v
            porovnání s mnohem dražší a pro pacienty náročnější léčbou.
          </p>
          <p>
            A za druhé, pamatujme, že chaos je časově náročný a zvedá hladinu
            kortizolu. Využijme tedy některé z organizačních pomůcek, ať už je
            to papírový diář, nebo ještě lépe jeho elektronický ekvivalent.
            Ideálně takový, který je flexibilní, mnohoúrovňový, funguje na všech
            vašich zařízeních a propojí vás s kýmkoliv potřebujete. Pak už si
            stačí jen nastavit upozornění a i ty neakutní preventivní kontroly
            se stanou automatickou součástí vašeho programu.
          </p>

          <div className="my-5">
            <a href={img1} target="_blank" rel="noreferrer">
              <img src={img1} alt="Demo showcase" className="object-contain" />
            </a>
          </div>
          <div className="my-5">
            <a href={img2} target="_blank" rel="noreferrer">
              <img src={img2} alt="Demo showcase" className="object-contain" />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={img3} target="_blank" rel="noreferrer">
              <img src={img3} alt="Demo showcase" className="object-contain" />
            </a>
          </div>
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
