import PropTypes from 'prop-types'
import React from 'react'

const ContentMain = ({ children }) => (
  <main className="md:pt-32 mb-auto">{children}</main>
)

ContentMain.defaultProps = {
  children: null,
}

ContentMain.propTypes = {
  children: PropTypes.any,
}

export { ContentMain }
